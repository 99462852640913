import * as React from "react"
import {Link} from "gatsby";
import BtnArrow from "../elements/btn-arrow";

const NotFoundSection = () => {
    return (
        <section className={`container not-found-section pt-32 md:pt-44 mx-auto`}>
            <div className="flex flex-wrap items-center justify-center text-center">
                <h1 className="basis-full not-found-text">
                    404
                </h1>
                <div className="flex flex-wrap items-center justify-center pt-5 text-center">
                    <h2 className="h2 w-[300px] md:w-[500px]">
                        Whoops... this page is not available
                    </h2>
                    <p className="pt-5 basis-full">
                        This page doesn't exist or was removed!
                    </p>
                    <Link className="pt-16 md:pt-10" to={`/`}>
                        <BtnArrow class={'btn-yellow text-base'} title={'Back to Homepage'} size={'btn-xxs'}/>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default NotFoundSection;