import * as React from "react"
import Layout from "../components/layout";
import NotFoundSection from "../components/sections/404-section";
import {graphql} from "gatsby";
import {DataProvider} from "../contentful/data-provider";

const NotFoundPage = ({data}) => {
    const dataProvider = new DataProvider(data);
    return (
        <Layout dataProvider={dataProvider}>
            <main>
                <NotFoundSection/>
            </main>
        </Layout>
    )
}

export default NotFoundPage;

export const query = graphql`
query {
  allContentfulMenu(filter: {node_locale: {eq: "en-US"}}) {
    edges {
      node {
        ... Menus
      }
    }
  }
}
`;

//comentedhead